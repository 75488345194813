@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&display=swap");
* {
    margin: 0;
    padding: 0;
    font-family: "Readex Pro", sans-serif;
}

html,
body {
    background: #ecf0f1;
    width: 100%;
    height: 100vh;
}