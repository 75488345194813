.done-base {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    color: #3d3d3d;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}

.done-icon {
    font-size: 6rem;
    color: #27ae60;
}

.done-title {
    margin-top: 1.2rem;
    font-size: 2.5rem;
}

.done-description {
    white-space: pre-line;
    color: #636e72;
    margin-top: 1rem;
}