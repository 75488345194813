.home-page {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    color: #3d3d3d;
    flex-direction: column;
}

.home-title {
    font-size: 2.5rem;
}

.home-description {
    font-size: 1.2rem;
}

.home-description {
    text-align: justify;
    text-align-last: center;
    margin: 4rem 0;
}