@import url(https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&display=swap);
* {
    margin: 0;
    padding: 0;
    font-family: "Readex Pro", sans-serif;
}

html,
body {
    background: #ecf0f1;
    width: 100%;
    height: 100vh;
}
.button-component {
    color: white;
    font-size: 2rem;
    padding: 1rem 2rem;
    cursor: pointer;
    border-radius: 0.5rem;
    white-space: nowrap;
}

.button-component:hover {
    -webkit-filter: brightness(95%);
            filter: brightness(95%);
}

.button-icon {
    font-size: 1.8rem;
    margin-right: 1rem;
}
.dispense-page {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    color: #3d3d3d;
    flex-direction: column;
}

.dispense-title {
    font-size: 2.5rem;
}

.dispense-description {
    text-align: justify;
    text-align-last: center;
    margin: 2rem 0;
    font-size: 1.2rem;
}

.dispense-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 2rem;
    margin-bottom: 2rem;
}

.dispense-description-each {
    color: #636e72;
    margin-top: 3rem;
}

.dispense-category-name {
    color: #e74c3c;
}

.item-base {
    background: white;
    border-radius: 1rem;
    width: 8rem;
    padding: 3rem;
    margin: 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.item-quantity {
    color: #7f8c8d;
    font-size: 1.5rem;
}

.item-icon {
    color: #7f8c8d;
    opacity: 0.5;
    font-size: 3rem;
}
.loader-base {
    display: flex;
    align-items: center;
    color: #3d3d3d;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}

.loader-title {
    margin-top: 1.2rem;
    font-size: 2.5rem;
}

.loader-description {
    white-space: pre-line;
    color: #636e72;
    margin-top: 1rem;
}
.category-page {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    color: #3d3d3d;
    flex-direction: column;
}

.category-title {
    font-size: 2.5rem;
}

.category-description {
    text-align: justify;
    text-align-last: center;
    margin: 3rem 0;
    font-size: 1.2rem;
}

.category-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.category-description-each {
    color: #636e72;
    margin-top: 3rem;
}

.aadhar-input {
    margin: 1.5rem 0;
    padding: 1rem;
    font-size: 2.2rem;
    outline: none;
    border: solid 2px white;
    border-radius: 0.5rem;
    letter-spacing: 0.5rem;
    width: 100%;
    text-align: center;
}

.aadhar-input:focus,
.aadhar-input:hover {
    border-color: #2980b9;
}

.aadhar-input::-webkit-input-placeholder {
    letter-spacing: 0.8rem;
}

.aadhar-input:-ms-input-placeholder {
    letter-spacing: 0.8rem;
}

.aadhar-input::placeholder {
    letter-spacing: 0.8rem;
}
.home-page {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    color: #3d3d3d;
    flex-direction: column;
}

.home-title {
    font-size: 2.5rem;
}

.home-description {
    font-size: 1.2rem;
}

.home-description {
    text-align: justify;
    text-align-last: center;
    margin: 4rem 0;
}
.done-base {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    color: #3d3d3d;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}

.done-icon {
    font-size: 6rem;
    color: #27ae60;
}

.done-title {
    margin-top: 1.2rem;
    font-size: 2.5rem;
}

.done-description {
    white-space: pre-line;
    color: #636e72;
    margin-top: 1rem;
}
